import { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/none';

import Wysiwyg from 'components/content-blocks/partials/wysiwyg';
import SignupForm from 'components/forms/signup-full';
import Yoast from 'components/globals/yoast';

import 'pages/styles/signup.scss';
import 'templates/styles/signup.scss';

export default function SignupTemplate({ pageContext }) {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "signup-parent" } }) {
        edges {
          node {
            wordpress_id
            content
          }
        }
      }
    }
  `);

  const wordPressId = pageContext.wordpress_id;
  const legalText = pageContext.acf.sign_up.legal;
  const yoast = pageContext.yoast_head_json;
  const content = data.allWordpressPage.edges[0].node.content;
  const featuresTitle = pageContext.acf.sign_up.features_title;
  const features = pageContext.acf.sign_up.features;
  const otherCompanyLogo = pageContext.acf.children_signup.company_logo.source_url;
  const quote = pageContext.acf.children_signup.quote;
  const customContent = pageContext.acf.children_signup.custom_content;
  const discount = pageContext.acf.children_signup.percent_discount;
  const promo = pageContext.acf.children_signup.promo_code;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <section className="signup-page-template-container signup-page-container light-grey" data-signal={ wordPressId }>
        <div className="container container-xlarge">
          <div className="signup-grid">

            <div className="signup-column" data-section="signupSection">
              <div className="tablet-logo-container is-hidden-tablet">
                <img
                  className="logo"
                  src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg"
                  alt="wiw-logo image"
                />
                <img className="plus" src="https://marketing-assets.wheniwork-production.com/2025/02/26153614/Small.svg"
                  alt="plus sign image" />
                <img className="partner-logo" src={ otherCompanyLogo }
                  alt="partner company logo image" />
              </div>
              <Fragment><h2 className="title">Sign Up and Schedule today!</h2>
                <SignupForm
                  uniqueId="signupPage"
                  legalText={ legalText }
                  ctaVariation="Single page"
                />
              </Fragment>
            </div>

            <div className="marketing-column">
              <div className="marketing-column-top">
                <div className="logo-container is-hidden-mobile">
                  <img className="logo" src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg" alt="wiw-logo image on right side of screen" />
                  <img className="plus" src="https://marketing-assets.wheniwork-production.com/2025/02/26153614/Small.svg" alt="plus sign image" />
                  <img className="partner-logo" src={ otherCompanyLogo } alt="partner company logo image right side of screen" />
                </div>
                <div
                  className="marketing-copy"
                  dangerouslySetInnerHTML={ { __html: content } }
                />
                <div className="quote">
                  <img
                    className="quotation-left"
                    loading="lazy"
                    src="https://marketing-assets.wheniwork-production.com/2025/02/26140727/%402x.png"
                    alt="fill me out"
                  />
                  <img
                    className="quotation-right"
                    loading="lazy"
                    src="https://marketing-assets.wheniwork-production.com/2025/02/26140814/%402x-1.png"
                    alt="fill me out"
                  />
                  <div className="quote-content">{quote.content}</div>
                  <div className="quote-from">{quote.from}</div>
                </div>
                <Wysiwyg wysiwygClasses="custom-content" content={ customContent } />
                <div className="promo-sticker">
                  <div className="discount">
                    <div className="text">
                      {discount}% <br /> OFF
                    </div>
                  </div>
                  <div className="code-container">
                    <div className="code-text">PROMO CODE</div>
                    <div className="promo">{promo}</div>
                  </div>
                </div>

              </div>
              <div className="marketing-column-bottom">
                <h3 className="title">{featuresTitle}</h3>
                <ul className="features">
                  {features.map(feature => {
                    return (
                      <li key={ feature.text } className="feature">
                        <div className="feature-content">
                          <img
                            className="feature-icon"
                            src={ feature.image.source_url }
                            alt="feature icon"
                          />
                          <p>{feature.text}</p>
                        </div>
                      </li>
                    );
                  }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}